<template>
    <div class="settlement">
        <header>
            <el-button @click="back" class="back">返回</el-button>
            <div class="title">结算</div>
            <div class="type">
                <div :class="['type_item',type==item.val?'type_active':'']" v-for="item in type_list" :key="item.val" @click="type = item.val">
                    <div class="dot">
                        <div></div>
                    </div>
                    <img :src="imgUrlHead+item.img+'.png'">
                    <div>{{item.label}}</div>
                </div>
            </div>
            <div class="price">
                <div class="price_item">
                    应收金额：<span>￥</span><span>{{(amount-card_money).toFixed(2)}}</span>
                </div>
            </div>
        </header>
        <main>
            <div class="left">
                <div class="left_main">
                    <div v-for="(item,index) in card_list" :key="index">
                        <div class="title">{{item.service_info.service_name}}</div>
                        <el-checkbox-group v-model="item.check" v-if="item.service_card.length>0">
                            <el-checkbox :label="c.id" v-for="c in item.service_card" :key="c.id"
                            :disabled="item.check.indexOf(c.id)==-1 && item.disabled" @change="chooseCard($event,c.id,item)">
                                <template>
                                    <img :src="c.card_item_picture">
                                    <div class="flex1">×{{c.card_item_sum}}</div>
                                </template>
                            </el-checkbox>
                        </el-checkbox-group>
                        <div class="no_card" v-else>暂无可用卡项</div>
                    </div>
                     <div class="no_card" v-if="!card_list">暂无可用卡项</div>
                </div>
                <footer>
                    抵用金额：
                    <span>￥</span>
                    <span>{{card_money.toFixed(2)}}</span>
                </footer>
            </div>
            <div class="right">
                <div v-for="item in keyboard" :key="item" class="item">{{item}}</div>
                <div class="item">
                    <img :src="imgUrlHead+'back.png'">
                </div>
                <div class="item item_confirm" @click="createOrder">确认结算</div>
            </div>
        </main>

        <!-- 改价 -->
        <el-dialog title="确认价格" :visible.sync="dialogVisible" width="54%">
            <el-table :data="change_price_list" :header-cell-style="{ 'background-color': '#F8F9FA' }" class="table" max-height="500">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column label="商品/服务信息" >
                    <template v-slot="{ row }">
                        <div class="userInfo">
                            <img :src="row.goods_img" class="goods_image">
                            <div class="flex1">
                                <div class="name">
                                    <img :src="imgUrlHead+(row.goods_type==3?'icon_serve.png':'icon_goods.png')">
                                    <span>{{row.goods_title}}</span>
                                </div>
                                <div>规格：{{row.sku_info?row.sku_info.title:'无'}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                 <el-table-column label="单价/数量" align="center" width="140">
                    <template v-slot="{ row }">
                        <div>{{row.goods_price}}</div>
                        <div>×{{row.goods_num}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="小计" align="center" width="140">
                    <template v-slot="{ row }">{{row.goods_pay_price}}</template>
                </el-table-column>
                <el-table-column label="改价后" align="center"  width="140">
                    <template v-slot="{ row }">
                        <el-input v-model="row.difference_price"></el-input>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <template v-if="change_price_list.length>0">
                    <div style="margin-bottom:30px;font-size:14px;">改价后价格
                        <span class="pay_price"><span>￥</span><span class="pay_price_price">{{change_price.toFixed(2)}}</span></span>
                    </div>
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirmChangePrice">确 定</el-button>
                </template>
            </span>
        </el-dialog>
        <!-- 付款 -->
        <el-dialog title="扫码支付（微信/支付宝)" :visible.sync="dialogPay" width="46%">
            <div class="pay_text">请用扫码枪扫描用户的{{type==1?'微信':'支付宝'}}支付码</div>
            <img :src="type==1?'https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Cashier/wechat_scan.png':'https://boweisou.oss-cn-shenzhen.aliyuncs.com/z_Cashier/ali_scan.png'" class="pay_img">
            <el-input v-model="auth_code" @keydown.enter.native="confirmPaymentCode" ref="authcode" style="opacity: 0;"></el-input>
        </el-dialog>
    </div>
</template>

<script>
import {imgUrlHead} from '@/util/config.js'
export default {
    props:{
        amount:{
            type:Number,
            default:0
        },
        card_list:{
            type:Array,
            default:()=>[]
        },
        handle_list:{
            type:Array,
            default:()=>[]
        },
    },
    data(){
        return{
            imgUrlHead:imgUrlHead+'beauty/',
            type:0,
            type_list:[{
                val:0,
                label:'现金支付',
                img:'type1'
            },{
                val:1,
                label:'微信支付',
                img:'type2'
            },{
                val:2,
                label:'支付宝支付',
                img:'type3'
            },{
                val:3,
                label:'POS刷卡',
                img:'type4'
            }],
            keyboard:['1','2','3','4','5','6','7','8','9','0','00','.'],
            dialogVisible:false,
            dialogPay:false,
            change_price_list:[],

            auth_code:'',
            order_id:'',
            pay_sn:''
        }
    },
    computed:{
        card_money(){
            return this.handle_list.reduce((total,item)=>{
                return total + (item.card_id?item.price*1:0)
            },0)
        },
        change_price(){
            return this.change_price_list.reduce((total,item)=>{
                return total + (item.difference_price?item.difference_price*1:item.goods_pay_price*1)
            },0)
        },
    },
    methods:{
        back(){
            this.$emit('back')
        },
        inputNum(item){
             this.$refs['change_price'].focus()
             this.change_price += item
        },
        backNum(){
            this.$refs['change_price'].focus()
            this.change_price = this.change_price.slice(0,-1)
        },
        chooseCard(val,id,item){
            if(val){
                let current_num = item.service_card.find(v=>v.id==id).card_item_sum
                for(let i=0;i<current_num;i++){
                    let first_index = this.handle_list.findIndex(v=>v.id == item.service_info.id&&!v.card_id)
                    if(first_index!=-1){
                        let obj = Object.assign({},this.handle_list[first_index])
                        obj.card_id = id
                        this.$set(this.handle_list,first_index,obj)
                    }
                }
                if(this.handle_list.filter(v=>v.id == item.service_info.id).every(v=>v.card_id)){
                    item.disabled = true
                }
            }else{
                let one_index = this.handle_list.findIndex(h=>h.id==item.service_info.id && h.card_id)
                if(one_index!=-1){
                    this.handle_list[one_index].card_id = ""
                    item.disabled = false
                }
            }
        },
        createOrder(){
            let sku_json = []
            this.handle_list.forEach(item=>{
                let obj_index = sku_json.findIndex(v=>v.goods_id==item.id&&v.sku_id==item.spec_id)
                if(obj_index!=-1){
                    sku_json[obj_index].goods_num++
                    if(item.card_id){
                        sku_json[obj_index].card_id = sku_json[obj_index].card_id?sku_json[obj_index].card_id.push(item.card_id):[item.card_id]
                    }
                }else{
                    sku_json.push({
                        goods_id:item.id,
                        sku_id:item.spec_id?item.spec_id:0,
                        goods_type:item.goods_type,
                        goods_num:1,
                        card_id:item.card_id?[item.card_id]:0
                    })
                }
            })
            let data = {
                appointment_id:this.$route.query.id,
                user_id:this.$parent.info.user_id,
                is_cashier:1,
                sku_json
            }

            this.$axios.post(this.$api.beauty.cashier.createOrder,data).then(res => {
                if (res.code == 0) {
                    this.order_id = res.result.order_id
                    this.pay_sn = res.result.pay_sn
                    this.getOrderInfo()
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getOrderInfo(){
            this.$axios.post(this.$api.beauty.orderInfo,{id:this.order_id}).then(res => {
                if (res.code == 0) {
                    let list = res.result.info.extend_order_goods
                    list.forEach(item=>{
                        item.difference_price = ''
                    })
                    this.change_price_list = list
                    this.dialogVisible = true
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        async confirmChangePrice(){
            let revise_goods = []
            this.change_price_list.forEach(item=>{
                if(item.difference_price){
                    revise_goods.push({
                        id:item.id,
                        goods_pay_price:item.price*item.num,
                        difference_price:item.difference_price
                    })
                }
            })
            if(revise_goods.length>0){
                let res = await this.$axios.post(this.$api.beauty.cashier.changePrice, {revise_goods})
                if (res.code != 0) {
                    return this.$message.error(res.msg);
                }
            }
            if(this.type == 0 || this.type == 3){
                this.confirmPayment()
            }else{
                this.dialogPay = true
                this.$nextTick(()=>{
                    this.$refs.authcode.focus()
                })
            }
        },
        confirmPayment(){
            this.$axios.post(this.$api.beauty.confirmPayment,{
                id:this.order_id,
                is_cashier:1
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('结算成功');
                    setTimeout(()=>{
                         window.close()
                    },1000)
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        confirmPaymentCode(){
            this.$axios.post(this.$api.beauty.cashier.pay,{
                    pay_sn:this.pay_sn,
                    auth_code:this.auth_code
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('结算成功');
                    setTimeout(()=>{
                         window.close()
                    },1000)
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    }
}
</script>

<style lang="less" scoped>
    .settlement{
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    header{
        height: 35%;
        background-color: #fff;
        box-shadow: 0px 0px 18px 1px rgba(116, 151, 188, 0.16);
        border-radius: 9px;
        padding: 15px 16px;
        box-sizing: border-box;
        position: relative;
        .back{
            position: absolute;
            top: 16px;
            left:18px;
        }
        .title{
            text-align: center;
            font-weight: bold;
            color: #606266;
            font-size: 29px;
            letter-spacing: 2px;
        }
        .type{
            margin-top:54px;
            display: flex;
            .type_item{
                flex:1;
                height: 72px;
                box-sizing: border-box;
                margin: 0;
                background: #FFFFFF;
                box-shadow: 0px 0px 18px 1px rgba(116, 151, 188, 0.16);
                border-radius: 10px;
                border: 1px solid #EDF2F7;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #606266;
                font-size: 20px;
                position: relative;
                cursor: pointer;
                &+.type_item{
                    margin-left: 32px;
                }
                img{
                    width: 27px;
                    height: 27px;
                    margin-right: 9px;
                }
                .dot{
                    position: absolute;
                    top: 9px;
                    left: 9px;
                    width: 19px;
                    height: 19px;
                    background: #DBDEE0;
                    border-radius: 50%;
                }
            }
            .type_active{
                background: #E2F0FF;
                border: 1px solid #409EFF;
                .dot{
                    background: #FFFFFF;
                    border: 1px solid #409EFF;
                    padding: 3px;
                    div{
                        background: #409EFF;
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .price{
            display: flex;
            align-items: center;
            margin-top: 45px;
            font-size: 13px;
            color: #606266;
            .price_item{
                span{
                    color: #F51C1C;
                    &:last-child{
                        font-size: 22px;
                        font-weight: bold;
                    }
                }
            }
            .row{
                display: flex;
                align-items: center;
                .el-input{
                    width: 150px;
                    margin-left: 9px;
                }
            }
        }
    }
    main{
        margin-top: 18px;
        display: flex;
        flex: 1;
        height: 0;
        .left{
            flex: 1;
            min-width: 400px;
            height: 100%;
            margin-right: 18px;
            background-color: #fff;
            border-radius: 9px;
            position: relative;
            overflow: hidden;
            box-shadow: 0px 0px 20px 1px rgba(116, 151, 188, 0.16);
            .left_main{
                overflow-y: auto;
                height: 100%;
                padding: 0 18px 60px;
                 &::-webkit-scrollbar{
                    width: 4px;
                }
                scrollbar-width: thin;
            }
            .title{
                font-weight: bold;
                color: #606266;
                padding: 20px 0;
            }
            /deep/.el-checkbox-group{
                &+.el-checkbox-group{
                    border-bottom: 1px solid #F3F4F5;
                }
                .el-checkbox{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 20px;
                    &.is-disabled{
                        cursor: no-drop;
                    }
                    .el-checkbox__input{
                        margin: 0 20px;
                    }
                    .el-checkbox__label{
                        flex: 1;
                        display: flex;
                        align-items: center;
                    }
                    img{
                        width: 182px;
                        height: 100px;
                        border-radius: 10px;
                    }
                    .flex1{
                        font-size: 32px;
                        font-weight: bold;
                        color: #409EFF;
                        text-align: center;
                    }
                }
            }
            .no_card{
                padding: 20px 0;
                text-align: center;
                font-size: 14px;
            }
            footer{
                position: absolute;
                left: 0;
                padding: 18px 20px;
                bottom: 0;
                right: 0;
                background: #FFFFFF;
                box-shadow: 0px -3px 10px 1px rgba(105, 134, 165, 0.16);
                font-size: 14px;
                color: #606266;
                z-index: 10;
                span{
                    color: #F51C1C;
                    &:last-child{
                        font-weight: bold;
                        font-size: 24px;
                    }
                }
            }
        }
        .right{
            background-color: #fff;
            border-radius: 9px;
            height: 100%;
            width: 440px;
            min-width: 440px;
            padding: 0 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            .item{
                width: 120px;
                height: 74px;
                background: #FFFFFF;
                box-shadow: 0px 0px 30px 1px rgba(116, 151, 188, 0.16);
                border-radius: 22px;
                border: 1px solid #EDF2F7;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                color: #606266;
                font-size: 24px;
                cursor: pointer;
                &:not(:nth-child(3n+3)){
                    margin-right: 20px;
                }
            }
            .item_confirm{
                width: 260px;
                height: 74px;
                background: linear-gradient(90deg, #7BBCFF 0%, #409EFF 100%);
                box-shadow: 0px 0px 10px 1px rgba(116, 151, 188, 0.16);
                color: #FFFFFF;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                margin-right: 0!important;
            }
        }
    }
    .table{
        .userInfo{
            display: flex;
            .goods_image{
                width: 70px;
                height: 70px;
                margin-right: 10px;
                align-items: center;
            }
            .flex1{
                width: 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .name{
                    width: 100%;
                    margin-bottom: 4px;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2;
                }
            }
        }
    }
    .pay_text{
        margin-top: 40px;
        text-align: center;
    }
    .pay_price{
        color: #F51C1C;
        margin-top: 30px;
        text-align: center;
        .pay_price_price{
            font-weight: bold;
            font-size: 24px;
        }
    }
    .pay_img{
        margin:30px auto 100px;
        display:block;
    }
</style>
