<template>
    <div class="goods">
        <header>
            <div class="tabs" ref="tabs">
                <div  :style="{'transform':'translateX(-'+translateX+'px)'}" class="tabsbox" ref="tabsbox">
                    <div v-for="(item,index) in classify" :key="item.id" :class="['tabs_item',form.classify_id==item.id?'tabs_active':'']" @click="changeTabs(item.id,index)" ref="tabsItem">{{item.classify_name}}</div>
                </div>
            </div>
            <div class="header_btn">
                <div :class="['header_btn_item',is_service?'header_btn_active':'']" @click="changeBtn(true)">服务</div>
                <div :class="['header_btn_item',is_service?'':'header_btn_active']" @click="changeBtn(false)">商品</div>
            </div>
        </header>
        <ul class="list infinite-list" v-infinite-scroll="loadlist" :infinite-scroll-disabled="no_more">
            <li v-for="item in list" class="list_item infinite-list-item" :key="item.id" @click="addList(item.id)">
                <img :src="item.picture" class="list_item_image">
                <div class="list_item_info">
                    <div class="info_name">{{item.name}}</div>
                    <div class="info_price">￥{{item.price}}</div>
                </div>
            </li>
            <div v-if="no_more" class="no_more">没有更多了</div>
        </ul>
        <div class="spec">
            <div v-show="open_spec" class="shadow" @click="open_spec = false"></div>
            <div class="spec_main" :style="{'height':open_spec?'428px':'0','width':width+'px'}">
                <div class="spec_top">
                    <div v-for="(item,index) in spec" class="spec_item" :key="item.id">
                        <div class="spec_name">{{item.spec_name}}</div>
                        <ul class="spec_val">
                            <li v-for="v in item.spec_value" :key="v.id" :class="[item.check_id==v.id?'spec_active':'']" @click="checkSpec(index,v.id)">{{v.spec_value}}</li>
                        </ul>
                    </div>
                </div>
                <div class="spec_bottom">
                    <el-button @click="open_spec = false">取 消</el-button>
                    <el-button type="primary" @click="confirmSpec">确 定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            classify:[],
            form:{
                page: 1,
                rows: 18,
                classify_id:0
            },
            is_service:true,
            translateX:0,

            list:[],
            no_more: false,
            open_spec:false,
            spec:'',
            width:0,
            current_goods:''
        }
    },
    created(){
        this.getServiceClassify()
        this.getServeList()
    },
    methods:{
        getServiceClassify(){
            this.$axios.post(this.$api.beauty.service.serviceClassifyList, {
                page: 1,
                rows: 10
            })
            .then(res => {
                if (res.code === 0) {
                    let list = res.result.list
                    if(list.length<10){
                        this.classify = list
                        this.classify.unshift({ classify_name: '全部服务', id: 0 })
                    }else{
                        let total = res.result.total_number
                        this.$axios.post(this.$api.beauty.service.serviceClassifyList, {
                            page: 1,
                            rows: total
                        })
                        .then(ress => {
                            if (ress.code === 0) {
                                let list = ress.result.list
                                this.classify = list
                                this.classify.unshift({ classify_name: '全部服务', id: 0 })
                            }
                        });
                    }
                }
            });
        },
        changeTabs(id,index){
            this.form.classify_id = id
            let tabsbox_scrollWidth = this.$refs.tabsbox.scrollWidth
            let tabs_clientWidth = this.$refs.tabs.clientWidth
            let difference = tabsbox_scrollWidth - tabs_clientWidth + 40
            let offsetLeft = this.$refs.tabsItem[index].offsetLeft - (tabs_clientWidth/2)
            let num = offsetLeft<difference?offsetLeft:difference
            this.translateX = num>0?num:0

            this.form.page = 1
            this.no_more = false
            this.list = []
            if(this.is_service){
                this.getServeList()
            }else{
                this.getGoodsList()
            }
        },
        changeBtn(flag){
            if(this.is_service != flag){
                this.is_service = flag
                this.form.page = 1
                this.no_more = false
                this.list = []
                if(flag){
                    this.getServiceClassify()
                    this.getServeList()
                }else{
                    this.getGoodsClass()
                    this.getGoodsList()
                }
            }
        },
        getServeList(){
            this.$axios.post(this.$api.beauty.service.serviceList,this.form)
            .then(res => {
                if (res.code === 0) {
                    let list = res.result.list.map(item=>{
                        return {
                            id:item.id,
                            picture:item.cover_picture,
                            name:item.service_name,
                            price:item.service_price
                        }
                    })
                    if(list.length<18){
                        this.no_more = true
                    }
                    this.list = this.list.concat(list)
                    let dom = document.getElementsByClassName('goods')[0]
                    this.width = dom.offsetWidth+20
                }
            });
        },
        loadlist(){
            if(this.list.length>0){
                this.form.page += 1
                if(this.is_service){
                    this.getServeList()
                }else{
                    this.getGoodsList()
                }
            }
        },
        addList(id){
            if(this.is_service){
                this.$axios.post(this.$api.beauty.service.serviceInfo,{id})
                .then(res => {
                    if (res.code === 0) {
                        let info = Object.assign({},res.result)
                        this.current_goods = info
                        if(info.is_spec){
                            this.open_spec = true
                            let spec = [...info.spec]
                            spec.forEach(item => {
                                item.check_id = 0
                            });
                            this.spec = spec
                        }else{
                            let data = {
                                id:info.id,
                                images:JSON.parse(info.service_picture)[0],
                                name:info.service_name,
                                spec_id:"",
                                spec_name:"",
                                price:info.service_price,
                                num:1,
                                orders:0,
                                goods_type:3
                            }
                            this.$emit('add-list',data)
                        }
                    }
                });
            }else{
                this.$axios.post(this.$api.goods.goodsInfo,{id})
                .then(res => {
                    if (res.code === 0) {
                        let info = Object.assign({},res.result)
                        this.current_goods = info
                        if(info.is_spec){
                            this.open_spec = true
                            let spec = [...info.spec]
                            spec.forEach(item => {
                                item.check_id = 0
                            });
                            this.spec = spec
                        }else{
                            let data = {
                                id:info.id,
                                images:info.goods_picture,
                                name:info.goods_name,
                                spec_id:"",
                                spec_name:"",
                                price:info.goods_price,
                                num:1,
                                orders:0,
                                goods_type:2
                            }
                            this.$emit('add-list',data)
                        }
                    }
                });
            }

        },
        confirmSpec(){
            if(this.spec.every(item=>item.check_id)){
                let str = this.spec.map(item=>item.check_id).toString()
                let info = this.current_goods
                let spec = ''
                let data = ''
                if(this.is_service){
                    spec = info.service_spec.find(item=>item.spec_itme_ids==str)
                    data = {
                        id:info.id,
                        images:JSON.parse(info.service_picture)[0],
                        name:info.service_name,
                        spec_id:spec.id,
                        spec_name:spec.title,
                        price:spec.price,
                        num:1,
                        orders:0,
                        goods_type:3
                    }
                }else{
                    spec = info.goods_spec.find(item=>item.spec_itme_ids==str)
                    data = {
                        id:info.id,
                        images:info.goods_picture,
                        name:info.goods_name,
                        spec_id:spec.id,
                        spec_name:spec.title,
                        price:spec.price,
                        num:1,
                        orders:0,
                        goods_type:2
                    }
                }
                this.$emit('add-list',data)
                this.open_spec = false
            }else{
                this.$message.warning('请选择完整规格')
            }
        },
        checkSpec(index,id){
            let obj = Object.assign({},this.spec[index])
            obj.check_id = obj.check_id==id?0:id
            this.$set(this.spec,index,obj)
        },
        getGoodsClass(){
            this.$axios.post(this.$api.goods.goodsClass,{is_show:2})
            .then(res => {
                if (res.code === 0) {
                    let list = res.result.map(item=>{
                        return {
                            classify_name:item.name,
                            id:item.id
                        }
                    })
                    this.classify = list
                    this.classify.unshift({ classify_name: '全部服务', id: 0 })
                }
            });
        },
        getGoodsList(){
            this.$axios.post(this.$api.goods.list,this.form)
            .then(res => {
                if (res.code === 0) {
                    let list = res.result.list.map(item=>{
                        return {
                            id:item.id,
                            picture:item.goods_picture,
                            name:item.goods_name,
                            price:item.goods_price
                        }
                    })
                    if(list.length<18){
                        this.no_more = true
                    }
                    this.list = [...this.list,...list]
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .goods{
        background-color: #fff;
        box-shadow: 0px 0px 20px 1px rgba(116, 151, 188, 0.16);
        border-radius: 10px;
        height: 100%;
        width: 100%;
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    header{
        height:78px;
        display:flex;
        align-items: center;
        .tabs{
            flex: 1;
            width: 0;
            height: 100%;
            overflow: hidden;
            box-sizing: border-box;
            position: relative;
            padding: 0 20px;
            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 20px;
                background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.38) 100%);
                z-index: 10;
                opacity: 0.97;
            }
            &::after{
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 20px;
                background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                z-index: 10;
                opacity: 0.97;
            }
            .tabsbox{
                display: flex;
                height: 100%;
                align-items: center;
                transition: 0.3s;

            }
            .tabs_item{
                flex-shrink: 0;
                font-weight: bold;
                color: #606266;
                height: 100%;
                line-height: 78px;
                cursor: pointer;
                &+.tabs_item{
                    margin-left: 50px;
                }
            }
            .tabs_active{
                color: #1467FF;
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: calc(50% - 15px);
                    width: 30px;
                    height: 3px;
                    background: #1467FF;
                }
            }
        }
        .header_btn{
            width: 128px;
            height: 36px;
            background: #FFFFFF;
            box-shadow: 0px 0px 15px 1px rgba(116, 151, 188, 0.16);
            border-radius: 18px;
            border: 1px solid #EDF2F7;
            margin:0 20px 0 36px;
            display: flex;
            box-sizing: initial;
            .header_btn_item{
                width: 64px;
                height: 36px;
                border-radius: 18px;
                text-align: center;
                line-height: 36px;
                color: #606266;
                background-color: #fff;
                cursor: pointer;
            }
            .header_btn_active{
                color: #ffffff;
                background-color: #409EFF;
            }
        }
    }
    .list{
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        height: 0;
        padding: 20px 0 0 20px;
        overflow-y: auto;
         &::-webkit-scrollbar{
            width: 4px;
        }
        scrollbar-width: thin;
        .list_item{
            width: 158px;
            height: 225px;
            background: #F0F1F2;
            border-radius: 6px;
            overflow: hidden;
            margin: 0 20px 20px 0;
            cursor: pointer;
            .list_item_image{
                width: 158px;
                height: 152px;
            }
            .list_item_info{
                padding: 10px;
                .info_name{
                    color: #606266;
                    margin-bottom: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .info_price{
                    color: #F51C1C ;
                }
            }
        }
    }
    .no_more{
        text-align: center;
        padding: 20px 0;
        width: 100%;
    }
    .spec{
        .shadow{
            position: fixed;
            background: rgba(0,0,0,0.3);
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 100;
        }

        .spec_main{
            position: fixed;
            z-index: 110;
            bottom: 0;
            right: 0;
            background: #F2F2F2;
            display: flex;
            flex-direction: column;
            transition: 0.2s;
            .spec_top{
                flex: 1;
                height: 0;
                overflow-y: auto;
                padding-top: 40px;
                .spec_item{
                    display: flex;
                    margin-bottom: 25px;
                    .spec_name{
                        min-width: 80px;
                        padding: 0 20px;
                        font-weight: bold;
                        color: #333333;
                        font-size: 16px;
                        line-height: 28px;
                        text-align: center;
                    }
                    .spec_val{
                        flex: 1;
                        width: 0;
                        display: flex;
                        flex-wrap: wrap;
                        li{
                            background: #FFFFFF;
                            border-radius: 14px;
                            padding: 0 20px;
                            margin-right: 20px;
                            margin-bottom: 15px;
                            line-height: 28px;
                            height: 28px;
                            color: #606266;
                            cursor: pointer;
                        }
                        .spec_active{
                            background: #CFE7FF;
                            color: #1387FF;
                        }
                    }
                }
            }
            .spec_bottom{
                height: 80px;
                background: #FFFFFF;
                padding: 0 20px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }
</style>
