<template>
    <div class="cashier" v-if="info">
        <el-form class="header">
            <el-form-item label="会员信息" label-width="73px">
                <el-input placeholder="输入用户手机号码" v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="30px">
                <el-button type="primary" @click="search">搜索</el-button>
            </el-form-item>
            <el-form-item class="flex1"></el-form-item>
             <el-form-item label=" " label-width="30px">
                <el-button  size="small" @click="back">返回后台</el-button>
            </el-form-item>
        </el-form>
        <main>
            <div class="left">
                <div class="user">
                    <img :src="info.avatar" v-if="info.avatar" class="avatar">
                    <div v-if="info.nick_name" class="nick_name">{{info.nick_name}}</div>
                    <div class="nick_name">{{info.name}}</div>
                    <div class="flex1">{{info.mobile}}</div>
                    <div v-if="info.order_person_detail.length>0">同行 <span style="color:#409EFF;font-size:24px;">{{info.order_person_detail.length}}</span> 人</div>
                </div>
                <div class="title">
                    <div class="title_left">消费明细（{{list.length}}）</div>
                    <el-link type="primary" :underline="false" @click="clearGoods" :disabled="is_settle">
                        <img :src="imgUrlHead+'delete.png'">
                        清空
                    </el-link>
                </div>
                <div class="list">
                    <div :class="['list_item',item.orders?'disabled':'']" v-for="(item,index) in list" :key="item.id">
                        <img :src="item.images" class="list_item_image">
                        <div class="list_item_info">
                            <div class="item_service_name">
                                <img :src="imgUrlHead+(item.goods_type==3?'icon_serve.png':'icon_goods.png')">
                                <span>{{item.name}}</span>
                            </div>
                            <div class="item_service_spec" v-if="item.spec_id">{{item.goods_type==3?'服务':''}}规格：{{item.spec_name}}</div>
                            <div class="item_price">￥{{item.price}}</div>
                        </div>
                        <div class="item_tools" @click="changeNum(item,'-')" :style="{'cursor':is_settle?'not-allowed':'pointer'}"></div>
                        <div class="item_num">{{item.num}}</div>
                        <div class="item_add item_tools" @click="changeNum(item,'+')" :style="{'cursor':is_settle?'not-allowed':'pointer'}"></div>
                        <div class="item_right">
                            <el-link type="primary" :underline="false" @click="delGoods(index)" :disabled="is_settle">
                                <img :src="imgUrlHead+'delete.png'">
                            </el-link>
                            <div class="item_status">{{item.orders?'已结算':'未结算'}}</div>
                            <div class="item_price2">￥{{(item.price*item.num).toFixed(2)}}</div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div class="footer_top">
                        <div style="margin-right:46px;">总数：{{num_all}}</div>
                        <div>未结算数：{{not_order_num}}</div>
                    </div>
                    <div class="footer_bottom">
                        <div>
                            <span class="text1">应收：</span>
                            <span class="text2">￥{{amount.toFixed(2)}}</span>
                        </div>
                        <div>
                            <el-button type="text" :disabled="is_settle" @click="clearServe">清空服务</el-button>
                            <el-button type="primary" @click="toSettle" :disabled="is_settle">确认结算</el-button>
                        </div>
                    </div>
                </footer>
            </div>
            <div class="right">
                <goods v-show="!is_settle" @add-list="addList"></goods>
                <settlement v-show="is_settle" @back="is_settle = false" :amount="amount" ref="settlement" :card_list="card_list" :handle_list="handle_list"></settlement>
            </div>
        </main>
  </div>
</template>

<script>
import goods from './components/goods'
import settlement from './components/settlement'
import {imgUrlHead} from '@/util/config.js'
export default {
    components:{
        goods,
        settlement
    },
    data(){
        return {
            imgUrlHead:imgUrlHead+'beauty/',
            form:{
                mobile:''
            },
            info:null,
            list:[],
            is_settle:false,

            order_list:[],
            card_list:[],
            handle_list:[],
        }
    },
    computed:{
        num_all(){
            return this.list.reduce((total,item)=>{
                return total + item.num
            },0)
        },
        not_order_num(){
            return this.list.reduce((total,item)=>{
                return total + (item.orders?0:item.num)
            },0)
        },
        amount(){
            return this.list.reduce((total,item)=>{
                return total + (item.orders?0:item.num*item.price)
            },0)
        }
    },
    created(){
        this.getInfo()
    },
    methods:{
        search(){

        },
        back(){
            window.close()
        },
        getInfo(){
            this.$axios.post(this.$api.beauty.serveDetail, {id:this.$route.query.id}).then(res => {
                if (res.code == 0) {
                    this.info = res.result
                    let list = [...res.result.service]
                    let arr = []
                    list.forEach(item=>{
                        arr.push({
                            id:item.service_id,
                            images:item.images,
                            name:item.service_name,
                            spec_id:item.service_spec_id,
                            spec_name:item.service_spec_name,
                            price:item.price,
                            num:1+this.info.order_person_detail.length,
                            orders:res.result.orders,
                            goods_type:3
                        })
                    })
                    this.list = arr
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        addList(obj){
            let index = this.list.findIndex(item=>item.id==obj.id && item.spec_id==obj.spec_id && !item.orders)
            if(index!=-1){
                this.list[index].num ++
            }else{
                this.list.push(obj)
            }
        },
        changeNum(item,str){
            if(!this.is_settle){
                if(str=='-'){
                    if(item.num>1){
                        item.num --
                    }
                }else{
                    item.num ++
                }
            }
        },
        clearGoods(){
            this.$confirm('确定清空未结算的服务和商品?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
            .then(() => {
                this.list = this.list.filter(item=>item.orders)
            })
            .catch(() => {});
        },
        delGoods(index){
            this.$confirm('确定删除该项?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
            .then(() => {
                this.list.splice(index,1)
            })
            .catch(() => {});
        },
        toSettle(){
            if(this.not_order_num>0){
                let service_id = this.list.filter(item=>!item.orders && item.goods_type==3).map(item=>item.id)
                service_id = service_id.filter((x, index,self)=>self.indexOf(x) === index)
                this.$axios.post(this.$api.beauty.cashier.cardList, {
                    appointment_id:this.$route.query.id,
                    service_id
                }).then(res => {
                    if (res.code == 0) {
                        let list = res.result.service_card_list
                        if(list){
                            list.forEach(item=>{
                                item.check = [],
                                item.disabled = false
                            })
                        }

                        let order_list = this.list.filter(item=>!item.orders).map(item=>{
                            let obj = Object.assign({},item)
                            obj.card_id = ''
                            return obj
                        })

                        let arr = []
                        order_list.forEach(item=>{
                            let obj = Object.assign({},item)
                            for(let i=0;i<obj.num;i++){
                                arr.push(obj)
                            }
                        })
                        this.handle_list = arr
                        this.order_list = order_list
                        this.card_list = list
                        this.is_settle = true
                        this.$refs.settlement.is_change_price = false
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }else{
                this.$message.warning('还没有未结算的订单')
            }
        },
        clearServe(){
            this.$confirm('确定清空未结算的服务?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
            .then(() => {
                this.list = this.list.filter(item=>item.orders || item.goods_type==2)
            })
            .catch(() => {});
        }
    }
}
</script>

<style lang="less" scoped>
.cashier{
    background-color: #F3F4F5;
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: 400;
}
.header{
    height: 90px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 18px;
    .el-form-item{
        margin-bottom: 0;
        .el-input{
            width: 267px;
        }
    }
}
main{
    flex: 1;
    height: 0;
    padding: 18px;
    display: flex;
    .left{
        width: 40%;
        min-width: 520px;
        height: 100%;
        margin-right: 27px;
        background-color: #fff;
        box-shadow: 0px 0px 20px 1px rgba(116, 151, 188, 0.16);
        border-radius: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        .user{
            height: 78px;
            border-bottom: 1px solid #F0F1F2;
            padding: 0 20px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #606266;
            .avatar{
                width: 48px;
                height: 48px;
                border-radius: 50%;
                margin-right: 15px;
            }
            .nick_name{
                margin-right: 56px;
            }
        }
        .title{
            height: 65px;
            display: flex;
            align-items: center;
            padding: 0 10px 0 20px;
            justify-content: space-between;
            border-bottom: 1px solid #F0F1F2;
            .title_left{
                font-size: 18px;
                font-weight: bold;
                color: #606266;
            }
        }
        .list{
            flex: 1;
            height: 0;
            overflow-y: auto;
            padding-bottom: 136px;
            &::-webkit-scrollbar{
                width: 4px;
            }
            scrollbar-width: thin;
            .list_item{
                display: flex;
                padding: 20px 10px 20px 20px;
                border-bottom: 1px solid #F0F1F2;
                align-items: center;

                .list_item_image{
                    width: 80px;
                    height: 80px;
                    margin-right: 15px;
                }
                .list_item_info{
                    flex: 1;
                    width: 0;
                    margin-right: 8%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 76px;
                    .item_service_name{
                        font-size: 16px;
                        font-weight: bold;
                        color: #606266;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        img{
                            margin-right: 2px;
                        }
                        span{
                            flex: 1;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 0;
                        }
                    }
                    .item_service_spec{
                        font-size: 12px;
                        color: #909399;
                         white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .item_price{
                        font-size: 14px;
                        color: #F51C1C;
                    }
                }
                .item_tools{
                    width: 20px;
                    height: 20px;
                    background: rgba(208, 208, 208, 0.6);
                    border-radius: 50%;
                    color: #87A4C2;
                    position: relative;
                    cursor: pointer;
                    &::before{
                        content: "";
                        position: absolute;
                        top: 9px;
                        left: 5px;
                        width: 10px;
                        height: 2px;
                        background: #87A4C2;
                    }
                }
                .item_num{
                    width: 50px;
                    font-size: 16px;
                    color: #2C3E50;
                    text-align: center;
                }
                .item_add{
                    margin-right: 15%;
                    &::after{
                        content: "";
                        position: absolute;
                        top: 5px;
                        left: 9px;
                        width: 2px;
                        height: 10px;
                        background: #87A4C2;
                    }
                }
                .item_right{
                    width: 60px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 76px;
                    align-items: flex-end;
                    .item_status{
                        font-size: 14px;
                        color: #2C3E50;
                    }
                    .item_price2{
                        font-size: 16px;
                        font-weight: bold;
                        color: #F51C1C;
                    }
                }
            }
            .disabled{
                position: relative;
                cursor: not-allowed;
                &::before{
                    content: "";
                    position: absolute;
                    background: rgba(255, 255, 255, 0.7);
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 10;
                }
            }
        }
        footer{
            padding: 30px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff;
            .footer_top{
                font-size: 14px;
                color: #2C3E50;
                display: flex;
                margin-bottom: 20px;
            }
            .footer_bottom{
                display: flex;
                justify-content: space-between;
                .text1{
                    color: #2C3E50;
                    font-size: 14px;
                }
                .text2{
                    font-weight: bold;
                    color: #F51C1C;
                    font-size: 24px;
                }
            }
        }
    }
    .right{
        flex: 1;
        width: 0;
    }
}
</style>
